import { Injectable } from '@angular/core';
import { Tag } from './tag';
import { CsvParserService } from '../csv-parser.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MocksService } from '../mocks/mocks.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  tags: Tag[] = [];

  constructor(
    private csvParserService: CsvParserService,
    private mocks: MocksService
  ) {
  }

  requestTags(mockPath: string): Observable<Tag[]> {
    return this.mocks.get(mockPath).pipe(
      map(csvContent => this.parse(csvContent))
    );
  }

  findByTagId(tagId: number): Tag | undefined {
    return this.tags.find(tag => tag.Id === tagId);
  }

  findByEpcs(epcs: string[]): Tag[] {
    return this.tags.filter(tag => epcs.includes(tag.EPC));
  }

  findByStartNumber(startNumber: number): Tag | undefined {
    return this.tags.find(tag => tag.Nr_startowy === startNumber);
  }

  setFromCsv(csvContent) {
    this.tags = this.parse(csvContent);
  }

  private parse(csvContent: string): Tag[] {
    return this.csvParserService.parse(csvContent)
      .map(row => ({
        Id: +row[0],
        EPC: row[1],
        Zawody_Id: +row[2],
        Nr_startowy: +row[3]
      } as Tag));
  }
}
