import { Component, OnInit } from '@angular/core';
import { MocksService } from '../mocks/mocks.service';
import { RacesService } from '../../ranking/races/races.service';
import { Race } from '../../ranking/races/race';
import { ResultsService } from '../../ranking/results/results.service';
import {
  mergeMap,
  map,
  switchMap,
  toArray,
  withLatestFrom,
  last,
  filter
} from 'rxjs/operators';
import { Observable, BehaviorSubject, from, combineLatest, OperatorFunction } from 'rxjs';
import { Result } from '../../ranking/results/result/result';
import { DriversService } from '../drivers/drivers.service';
import { Driver } from '../drivers/driver';
import * as moment from 'moment';
import { GateReadingsService } from '../gate-readings/gate-readings.service';
import { ResultsConfig } from '../../ranking/results/results-config/results-config';
import { StandingsConfig } from '../../ranking/standings/standings-config';
import { GateReading } from '../gate-readings/gate-reading';
import { PzGateReading } from './pz-gate-reading';
import { StandingsService } from '../../ranking/standings/standings.service';
import { Standing } from '../../ranking/standing';

interface ResultsMapItem {
  result: Result;
  timestamp: number;
  priority: number;
}

function compareByKeysFn(keys: string[]) {
  return (a, b) => a[keys[0]] > b[keys[0]]
    ? 1
    : a[keys[0]] < b[keys[0]]
      ? -1
      : keys.length > 1 ? compareByKeysFn(keys.slice(1))(a, b) : 0;
}

@Component({
  selector: 'mc-backup-results-merge',
  templateUrl: './backup-results-merge.component.html',
  styleUrls: ['./backup-results-merge.component.scss']
})
export class BackupResultsMergeComponent implements OnInit {

  results: Result[] = [];

  race$: Observable<Race> = this.racesService.requestRaces().pipe(
    mergeMap(races => races),
    // filter(race => race.id === 4),
    last(),
    filter(race => !!race.rawGepardGateReadingsFiles?.length)
  );

  standingsConfig$ = new BehaviorSubject<StandingsConfig>({});
  resultsConfig$ = new BehaviorSubject<ResultsConfig>({});

  standings$: Observable<Standing[]> = this.race$.pipe(
    switchMap((race: Race) => {
      this.resultsConfig$.next(race.resultsConfig);

      return combineLatest([
        from([race]),
        this.driversService.requestDriversCsv(race.driversCsvFile)
      ]);
    }),
    switchMap(([race, drivers]: [Race, Driver[]]) => {
      this.driversService.drivers = drivers;

      return combineLatest([
        this.mocksService.get(race.rawGepardGateReadingsFiles[0]).pipe(
          switchMap(content => this.parseRawGepardGateReadingsFile(content)),
          // switchMap(jsonContent => JSON.parse(jsonContent)),
          BackupResultsMergeComponent.pzGateReadingToGateReadingOperator(),
          toArray()
        ),
        // from(race.gateReadingsCsvFiles).pipe(
        //   mergeMap(gateReadingsCsvFile => this.gateReadingsService.requestGateReadings(gateReadingsCsvFile)),
        // )
      ]).pipe(
        mergeMap((gateReadingsCollections: GateReading[][]): Observable<GateReading> => GateReadingsService.concat.apply(null, gateReadingsCollections)),
        map((gateReading: GateReading): [GateReading, number] => [gateReading, 2]),
        this.gateReadingsService.filterThresholdOperator(),
        withLatestFrom([drivers], this.standingsConfig$, this.resultsConfig$),
        // tap(([gateReading, drivers, standingsConfig, resultsConfig]: [GateReading, Driver[], StandingsConfig, ResultsConfig]) => console.log(gateReading, drivers, standingsConfig, resultsConfig)),
        this.gateReadingsService.mapToResultsOperator(),
        // filter((result: Result) => result.driverId === 91 && result.createdAt > '2019-05-11 11:47:00' && result.createdAt < '2019-05-11 11:49:00'),
        toArray(),
        withLatestFrom(this.resultsService.requestResults(race.resultsJsonFile)),
        map(([moreResults, results]) => this.results = this.mergeMissingResults([results, moreResults])),
        mergeMap((results: Result[]): Observable<Standing[]> => from(this.standingsService.fromResults(results)).pipe(
          withLatestFrom(this.resultsConfig$),
          map(([standing, resultsConfig]: [Standing, ResultsConfig]): Standing => this.standingsService.getRecalculated(standing, resultsConfig)),
          toArray()
        ))
      );
    })
  );

  jsonExport = null;

  constructor(
    private racesService: RacesService,
    private resultsService: ResultsService,
    private mocksService: MocksService,
    private driversService: DriversService,
    private gateReadingsService: GateReadingsService,
    private standingsService: StandingsService
  ) {
  }

  ngOnInit() {
  }

  parseRawGepardGateReadingsFile(fileContent: string): PzGateReading[] {
    return fileContent
      .split('event.tag.arrive ')
      .filter(line => !!line)
      .map((line, i) => {
        let template = {
          antenna: undefined,
          id: undefined,
          internal_id: undefined,
          key: undefined,
          name: undefined,
          tag_id: undefined,
          timestamp: undefined
        } as PzGateReading;
        const elements = line.split(/,|\s+/);
        const tagIdElement = elements.find(element => element.match(/^tag_id=/));
        const tag_id = tagIdElement ? tagIdElement.replace(/^tag_id=/, '') : undefined;
        const timestampElement = elements.find(element => element.match(/^first=/));
        const timestamp = timestampElement ? timestampElement.replace(/^first=/, '') : undefined;
        return Object.assign({}, template, {
          internal_id: i,
          tag_id,
          timestamp
        });
      });
  }

  static pzGateReadingToGateReadingOperator(correctIdsBy: number = 666000000): OperatorFunction<PzGateReading, GateReading> {
    return stream => stream.pipe(
      map((pzGateReading: PzGateReading) => {
        const createdAt = moment.utc(pzGateReading.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS');
        return {
          gr_id: pzGateReading.internal_id + correctIdsBy,
          gate_id: 20,
          tag_code: pzGateReading.tag_id.substr(6, 8),
          first_detection_date: createdAt,
          last_detection_date: createdAt,
          save_date: createdAt,
          status: 0,
          lap: null
        };
      })
    );
  }

  private mergeMissingResults(resultsCollections: Result[][], duplicationMarginMilliseconds: number = 1000) {
    const mergedResults = [];
    const sortedItemsByDriversMap: { [driverId: number]: ResultsMapItem[] } = {};

    // Create map:
    resultsCollections
      // Wrap results in ResultsMapItem objects and concat:
      .reduce((prev, next, i) => prev.concat(next.map(result => ({
        result,
        priority: i + 1,
        timestamp: result.createdAt
      }))), [] as ResultsMapItem[])
      // Sort concatenated map items by timestamp:
      .sort(compareByKeysFn(['timestamp']))
      // Group items by drivers:
      .forEach(item => {
        if (!(item.result.driverId in sortedItemsByDriversMap)) {
          sortedItemsByDriversMap[item.result.driverId] = [];
        }
        sortedItemsByDriversMap[item.result.driverId].push(item);
      });

    // Reduce:
    Object.values(sortedItemsByDriversMap).forEach(items => {
      let i = 0;
      while (i < items.length) { // Iterate over driver's results.
        let n = i + 1;
        while (n < items.length && items[n].timestamp < items[i].timestamp + duplicationMarginMilliseconds) {
          n++;
        }
        mergedResults.push(items.slice(i, n).sort(compareByKeysFn(['priority', 'timestamp']))[0].result);
        i = n;
      }
    });

    return mergedResults;
  }

  exportResults() {
    this.jsonExport = this.results;
  }
}
